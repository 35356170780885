import AuthRoute from 'components/AuthRoute/AuthRoute';
import Layout from 'components/Layout/Layout';
import PrivateLayout from 'components/PrivateLayout/PrivateLayout';
import RequireConnectionLayout from 'components/RequireConnectionLayout/RequireConnectionLayout';
import { CountriesContext } from 'context/CountriesContext';
import { LanguageContext } from 'context/Language/LanguageContext';
import Languages from 'context/Language/Languages';
import { RegistrationTypesContext } from 'context/RegistrationTypesContext';
import { UserTypeContext } from 'context/User/UserTypeContext';
import { createBrowserHistory } from "history";
import { IUserLanguage } from 'interfaces/User/IUserLanguage';
import { Appointment, AppointmentValidation, ConsentForm, ContactUs, CookiePolicy, DashboardHome, DashboardOfferKit, DashboardOrderKit, DietarySupplementCreation, DoctorGeneralComment, DoctorMeeting, DoctorNutritionalComment, DoctorPrescription, DoctorPreventiveAdvice, DocumentLanguageSelection, DownloadApp, Employees, FaqDoctor, Foods, GeneralCommentCreation, History, HowItWorks, Invoicing, Login, Maintenance, Messaging, MultipleTimeValidation, NewResetPassword, NotFound, NutritionalCommentCreation, OneTimeValidation, OrderDetail, OrderSolution, Patient, PatientList, PatientMeeting, PrescriptionCreation, Prescriptions, PreventiveAdviceCreation, Privacy, Profile, RecommendationsBase, RecommendationsDetailed, Register, RegisterKit, RequestReset, SolutionSelection, Support, Survey, SurveyViewDoctor, SurveyViewPatient, TestResults, Validation } from 'pages';
import Base from 'pages/ErrorBoundary/Base';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Cookies, CookiesProvider } from "react-cookie";
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import languageService from 'services/language.service';
import userAuthService from 'services/user-auth.service';
import verifierService from 'services/verifier.service';
import './App.css';

export const history = createBrowserHistory();

const App: React.FC = () => {
    const cookies = new Cookies();

    const { language, setLanguage } = useContext(LanguageContext);
    const { type, refreshType } = useContext(UserTypeContext);
    const { registrationTypes, refreshRegistrationTypes } = useContext(RegistrationTypesContext);
    const { countries, reloadCountries } = useContext(CountriesContext);
    const [ countryError, setCountryError ] = useState();

    const loadNavigatorLanguage = (): IUserLanguage => {
        const frontCookie = cookies.get('bcu_lang');

        if (frontCookie && Languages.filter((lang) => lang.countryCode === frontCookie).length > 0) {
            return Languages.filter((lang) => lang.countryCode === frontCookie)[0];
        }

        const userNavigatorLanguage = navigator.language.split('-')[0];

        let lng = Languages.filter((lang) => lang.countryCode === userNavigatorLanguage)[0];

        if (!lng) lng = Languages.filter((lang) => lang.default)[0];

        return lng;
    }

    const getCurrentLanguage = async () => {
        if (userAuthService.isToken()) {
            return await languageService.getCurrentLanguage()
                .then((res) => {
                    let lng = Languages.filter((lang) => lang.countryCode === res.iso6391)[0];

                    if (!lng) lng = Languages.filter((lang) => lang.default)[0];

                    return lng;
                })
                .catch(() => loadNavigatorLanguage())
        } else {
            return loadNavigatorLanguage();
        }
    }

    const loadLanguage = useCallback(() => {
        getCurrentLanguage().then((lng) => setLanguage(lng));
    }, []);

    useEffect(() => {
        loadLanguage();
    }, [loadLanguage]);

    const handleClickLanguage = (newLanguage: IUserLanguage) => {
        if (userAuthService.isToken()) {
            languageService.saveCurrentLanguage(newLanguage.countryCode);
        }
    }

    useEffect(() => {
        handleClickLanguage(language);
    }, [ language ]);

    useEffect(() => {
        if (!countryError && countries.length === 0) {
            reloadCountries()
                .then(() => setCountryError(undefined))
                .catch((err) => setCountryError(err.message));
        }
    }, [ countryError, countries ]);

    useEffect(() => {
        if (verifierService.checkString(userAuthService.getToken())) {
            if (!type.isDefined()) {
                refreshType();
            }
        }
    }, [ type, refreshType ]);

    useEffect(() => {
        if (type && type.isDoctor() && registrationTypes.length === 0) {
            refreshRegistrationTypes();
        }
    }, [type, refreshRegistrationTypes]);

    function renderRouter() {
        if (process.env.REACT_APP_MAINTENANCE_MODE === "ON") {
            return <Maintenance />
        }
        else {
            return (
                <Switch>
                    <Route path='/account/:path?'>
                        <RequireConnectionLayout>
                            <Switch>
                                <AuthRoute path="/account/meeting/withpatient/:patientId/:roomId" component={DoctorMeeting} requiredRoles={[ "Doctor" ]} />
                                <AuthRoute path="/account/meeting/withdoctor/:doctorId/:roomId" component={PatientMeeting} requiredRoles={[ "Patient", "Standalone" ]} />
                                <Route path='/account/kit/:path?'>
                                    <Switch>
                                        <AuthRoute path="/account/kit/consent-form" component={ConsentForm} requiredRoles={[ "Patient", "Staff", "Standalone" ]} />
                                        <AuthRoute path="/account/kit/questionnaire" component={Survey} requiredRoles={[ "Patient", "Staff", "Standalone" ]} />
                                        <AuthRoute path="/account/kit/register-your-kit" component={RegisterKit} requiredRoles={[ "Patient", "Staff", "Standalone" ]} />
                                        <PrivateLayout>
                                            <Route component={NotFound} />
                                        </PrivateLayout>
                                    </Switch>
                                </Route>
                                <PrivateLayout>
                                    <Switch>
                                        <AuthRoute path="/account/order-kit/session/:id" component={OneTimeValidation} requiredRoles={[ "Company", "Standalone" ]} />
                                        <AuthRoute path="/account/order-kit/subscription/:id" component={MultipleTimeValidation} requiredRoles={[ "Company", "Standalone" ]} />
                                        <AuthRoute path="/account/order-kit" component={DashboardOrderKit} requiredRoles={[ "Company", "Standalone" ]} />
                                        <AuthRoute path="/account/offer-kit" component={DashboardOfferKit} requiredRoles={[ "Standalone" ]} />
                                        <AuthRoute path="/account/profile" component={Profile} requiredRoles={[ "Doctor" , "Patient", "Staff", "Company", 'Standalone' ]} />
                                        <AuthRoute path="/account/employees" component={Employees} requiredRoles={[ "Company" ]} />
                                        <AuthRoute path="/account/invoicing" component={Invoicing} requiredRoles={[ "Company", "Standalone" ]} />
                                        <AuthRoute path="/account/recommendations/details" component={RecommendationsDetailed} requiredRoles={[ "Patient", "Standalone" ]} />
                                        <AuthRoute path="/account/recommendations" component={RecommendationsBase} requiredRoles={[ "Patient", "Standalone" ]} />
                                        <AuthRoute path="/account/patient/list" component={PatientList} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/questionnaire/overview" component={SurveyViewPatient} requiredRoles={[ "Patient", "Standalone" ]} />
                                        <AuthRoute path="/account/patient/:patientId/questionnaire" component={SurveyViewDoctor} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/patient/:patientId/prescription/:appointmentId/new" component={PrescriptionCreation} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/patient/:patientId/prescription/:appointmentId/" component={DoctorPrescription} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/patient/:patientId/dietarySupplement/:appointmentId/new" component={DietarySupplementCreation} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/patient/:patientId/dietarySupplement/:appointmentId/" component={DoctorPrescription} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/patient/:patientId/general-comment/:appointmentId/new" component={GeneralCommentCreation} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/patient/:patientId/general-comment/:appointmentId/" component={DoctorGeneralComment} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/patient/:patientId/nutritious-comment/:appointmentId/new" component={NutritionalCommentCreation} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/patient/:patientId/nutritious-comment/:appointmentId" component={DoctorNutritionalComment} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/patient/:patientId/preventive-advice/:appointmentId/new" component={PreventiveAdviceCreation} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/patient/:patientId/preventive-advice/:appointmentId" component={DoctorPreventiveAdvice} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/patient/:patientId" component={Patient} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/home" component={DashboardHome} />
                                        <AuthRoute path="/account/history" component={History} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/questions" component={FaqDoctor} requiredRoles={[ "Doctor" ]} />
                                        <AuthRoute path="/account/messaging" component={Messaging} requiredRoles={[ "Doctor",  "Patient", "Company", "Standalone" ]} />
                                        <AuthRoute path="/account/tests" component={TestResults} requiredRoles={[ "Patient", "Standalone" ]} />
                                        <AuthRoute path="/account/order/:orderId" component={OrderDetail} requiredRoles={[ "Company" ]} />
                                        <AuthRoute exact path="/account/support" component={Support} />
                                        <AuthRoute path="/account/appointments" component={Appointment} requiredRoles={[ "Doctor", "Patient", "Staff", "Standalone" ]} />
                                        <AuthRoute path="/account/prescription" component={Prescriptions} requiredRoles={[ "Patient", "Standalone" ]} />
                                        <AuthRoute path="/account/foods" component={Foods} requiredRoles={[ "Patient", "Staff", "Standalone" ]} />
                                        <Route component={NotFound} />
                                    </Switch>
                                </PrivateLayout>
                                <Route component={NotFound} />
                            </Switch>
                        </RequireConnectionLayout>
                    </Route>
                    <Route>
                        <Layout>
                            <Switch>
                                <Redirect exact path='/' to="/login" />
                                {/* <Route exact path="/" component={Home} /> */}
                                {/* <Route exact path="/products" component={Products} /> */}
                                <Route exact path="/how-it-works" component={HowItWorks} />
                                {/* <Route exact path="/performance" component={Performance} /> */}
                                {/* <Route exact path="/performance/articles" component={PerformanceArticles} /> */}
                                {/* <Route exact path="/our-solution" component={OurSolution} /> */}
                                {/* <Route exact path="/complete-kit" component={ProductComplete} /> */}
                                {/* <Route exact path="/classic-kit" component={ProductClassic} /> */}
                                {/* <Route exact path="/no-pills-kit" component={ProductNoPills} /> */}
                                {/* <Route exact path="/one-time-kit" component={ProductOneTime} /> */}
                                {/* <Route exact path="/questions" component={FAQ} /> */}
                                {/* <Route exact path="/markers" component={Markers} /> */}
                                {/* <Route exact path="/about" component={AboutUs} /> */}
                                {/* <Route exact path="/our-team" component={OurTeam} /> */}
                                {/* <Route exact path="/science" component={Science} /> */}
                                {/* <Route exact path="/conditions" component={Terms} /> */}
                                <Route exact path="/cookies" component={CookiePolicy} />
                                <Route exact path="/download-app" component={DownloadApp} />
                                <Route exact path="/contact-us" component={ContactUs} />
                                <Route exact path="/privacy" component={Privacy} />
                                <Route exact path="/language-selection/:documentType" component={DocumentLanguageSelection} />
                                <Route exact path="/order" component={SolutionSelection} />
                                <Route exact path="/order/appointment/validation" component={AppointmentValidation} />
                                <Route exact path="/order/validation" component={Validation} />
                                <Route exact path="/order/:solutionName" component={OrderSolution} />
                                <Route exact path="/login" component={Login} />
                                <Route exact path="/register" component={Register} />
                                <Route exact path="/account-recovery" component={RequestReset} />
                                <Redirect exact path="/reset" to={"/account-recovery"} />
                                <Route exact path="/reset/:request" component={NewResetPassword} />
                                <Route component={NotFound} />
                            </Switch>
                        </Layout>
                    </Route>
                </Switch>
            );
        }
    }

    return (
        <CookiesProvider>
            <ErrorBoundary FallbackComponent={Base}>
                <Router>{renderRouter()}</Router>
            </ErrorBoundary>
        </CookiesProvider>
    );
}

export default App;
