import { Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { Select } from "components/Form";
import { CheckCircleIcon, EditIcon } from "components/Icons";
import { IDAddress, IDPlatform, IDProductOrderType } from "interfaces/Database";
import PaymentType from "interfaces/Database/IDatabasePaymentType";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "services";
import addressService from "services/address.service";
import usePaymentTypeService from "services/api/paymentType.service";
import usePlatformService from "services/api/platform.service";
import useProductOrderTypeService from "services/api/productOrderType.service";
import './BuyAppointment.scss';
import AppointmentPayment from "./components/AppointmentPayment";

interface IBuyAppointmentSelection {
    id: string
    question: string
    type: 'doctor' | 'nutritionist',
    category: 'guided' | 'choice'
}

const BuyAppointment: React.FC = () => {
    const t = useTranslation('components/buyAppointment');

    const platformService = usePlatformService();
    const productOrderTypeService = useProductOrderTypeService();
    const paymentTypeService = usePaymentTypeService();

    const options: IBuyAppointmentSelection[] = [
        {
            id: '1',
            question: 'weightLoss',
            type: 'nutritionist',
            category: 'guided'
        },
        {
            id: '2',
            question: 'increaseSleepQuality',
            type: 'doctor',
            category: 'guided'
        },
        {
            id: '3',
            question: 'optimizeDigestive',
            type: 'nutritionist',
            category: 'guided'
        },
        {
            id: '4',
            question: 'lowerStress',
            type: 'doctor',
            category: 'guided'
        },
        {
            id: '5',
            question: 'strengthenImmune',
            type: 'doctor',
            category: 'guided'
        },
        {
            id: '6',
            question: 'generalCheckup',
            type: 'doctor',
            category: 'guided'
        },
        {
            id: '7',
            question: 'specialist',
            type: 'doctor',
            category: 'choice'
        },
        {
            id: '8',
            question: 'nutritionist',
            type: 'nutritionist',
            category: 'choice'
        }
    ]

    const [ isLoadingPayment, setIsLoadingPayment ] = useState<boolean>(false);

    const [ appointmentType, setAppointmentType ] = useState<IBuyAppointmentSelection | null>(null);

    const [ billingAddress, setBillingAddress ] = useState<IDAddress>();
    const [ platform, setPlatform ] = useState<IDPlatform>();
    const [ paymentTypes, setPaymentTypes ] = useState<PaymentType[]>([]);
    const [ productOrderType, setProductOrderType ] = useState<IDProductOrderType>();

    const appointmentTypeDivRef = useRef<HTMLDivElement>(null);
    const paymentDivRef = useRef<HTMLDivElement>(null);

    const handleSelectAppointmentType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const opt = options.filter((option) => option.id === e.currentTarget.value)[0];

        if (opt) {
            setAppointmentType(opt);
        } else {
            setAppointmentType(null);
        }
    }

    useEffect(() => {
        if (appointmentType) {
            setIsLoadingPayment(true);

            Promise.all([
                addressService.getAddresses()
                    .then((res) => setBillingAddress(res.billing)),
                platformService.getWebPlatform(),
                productOrderTypeService.getAppointmentType(),
                paymentTypeService.getAllPaymentTypes(),
            ])
                .then((res) => {
                    setPlatform(res[1]);
                    setProductOrderType(res[2]);
                    setPaymentTypes(res[3]);
                })
                .catch((err) => console.warn(err))
                .finally(() => setIsLoadingPayment(false));
        }
    }, [appointmentType]);

    return (
        <DefaultContainer
            title="Buy Appointment"
            body={
                <div className="flex-row align-items-stretch flex-wrap flex-gap-col flex-gap-row">
                    <div className='order-informations-wrapper-step' ref={appointmentTypeDivRef}>
                        <div className='order-informations-wrapper-step-title'>
                            {appointmentType
                                ? <CheckCircleIcon size={32} />
                                : <p>1.</p>
                            }
                            <div>
                                <h2 style={appointmentType ? { marginBottom: 0 } : {}}>{t.translate('steps.appointmentType.title')}</h2>
                                {appointmentType
                                    && <p style={{ marginTop: 0 }}>
                                        {t.translate(`appointmentTypeSelection.${appointmentType.question}`)}
                                    </p>
                                }
                            </div>
                            {appointmentType
                                && <EditIcon className='clickable' onClick={() => setAppointmentType(null)} style={{ position: 'absolute', right: '0' }} />
                            }
                        </div>
                        {(
                            !appointmentType
                        ) &&
                            <Select
                                name="appointmentType"
                                value={appointmentType}
                                setValue={handleSelectAppointmentType}
                                options={
                                    <Fragment>
                                        <option>{t.translate('steps.appointmentType.type.choose')}</option>
                                        <optgroup label={t.translate('steps.appointmentType.meetingObjective')}>
                                            {options.filter((e) => e.category === 'guided').map((e) => {
                                                return <option key={`opt-${e}`} value={e.id}>{
                                                    t.translate(`appointmentTypeSelection.${e.question}`)
                                                }</option>
                                            })}
                                        </optgroup>
                                        <optgroup label={t.translate('steps.appointmentType.chooseType')}>
                                            {options.filter((e) => e.category === 'choice').map((e) => {
                                                return <option key={`opt-${e}`} value={e.id}>{
                                                    t.translate(`appointmentTypeSelection.${e.question}`)
                                                }</option>
                                            })}
                                        </optgroup>
                                    </Fragment>
                                }
                            />
                        }
                    </div>
                    <div className='order-informations-wrapper-step' ref={paymentDivRef} style={{ marginBottom: '12vh' }}>
                        <div className='order-informations-wrapper-step-title'>
                            <p>2.</p>
                            <div>
                                <h2 style={appointmentType ? { marginBottom: 0, marginTop: 0 } : {}}>{t.translate('steps.payment.title')}</h2>
                            </div>
                        </div>
                        {(
                            appointmentType &&
                            paymentTypes &&
                            platform &&
                            productOrderType &&
                            billingAddress
                        ) && (isLoadingPayment
                            ? <Loader />
                            : <AppointmentPayment
                                appointmentType={appointmentType.type}
                                paymentTypes={paymentTypes}
                                platform={platform}
                                productOrderType={productOrderType}
                                renew={false}
                                billingAddress={billingAddress}
                                togglePreviousStep={() => setAppointmentType(null)}
                            />
                        )}
                    </div>
                </div>
            }
        />
    )
}

export default BuyAppointment;