import { Loader } from "components";
import { Button, Input, Label, LabelInput } from "components/Form";
import RegisterForm from "components/Register/components/RegisterForm";
import { UserContext } from "context/User/UserContext";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";

export interface IdentifyProps {
    refresh: () => void,
    toggleConnecting: () => void
}

// eslint-disable-next-line react/prop-types
const Identify: React.FC<IdentifyProps> = ({ refresh, toggleConnecting }) => {
    const t = useTranslation('pages/orderSolution');
    const history = useHistory();

    const { refreshUser } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ shouldLogOrRegister, setShouldLogOrRegister ] = useState<'login' | 'register'>();

    const [ email, setEmail ] = useState<string>();
    const [ password, setPassword ] = useState<string>();

    const handleVerifyEmail = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (email) {
            setIsLoading(true);

            userAuthService.accountExisting(email)
                .then((res) => setShouldLogOrRegister(res ? 'login' : 'register'))
                .catch(() => setShouldLogOrRegister('register'))
                .finally(() => {
                    setIsLoading(false);
                    toggleConnecting();
                });
        }
    }

    const handleResetPassword = () => {
        history.push('/account-recovery', { email: email });
    }

    const handleLogin = (props: { username: string, password: string }, event?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        setIsLoading(true);

        userAuthService.login(props)
            .then(async () => {
                await refreshUser();
                await refresh();
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }
    
    const handleToggleCreateAccount = () => {
        setEmail(undefined);
        setShouldLogOrRegister('register');
    }
    const handleToggleLogin = () => {
        setEmail(undefined);
        setShouldLogOrRegister('login');
    }

    if (isLoading) {
        return <Loader />
    }

    if (!shouldLogOrRegister) {
        return (
            <form className='full-width flex-column' onSubmit={handleVerifyEmail}>
                <LabelInput
                    required
                    name="email"
                    label={t.translate('steps.login.enterEmail')}
                    value={email}
                    setValue={(e) => setEmail(e.currentTarget.value)}
                />
                
                <Button label={t.translate('steps.login.button.next')} type="submit" style={{ marginTop: '1vh' }} />
            </form>
        )
    }

    return (
        <div className='full-width flex-column'>
            {shouldLogOrRegister === 'login'
                ? <form className="flex-column" onSubmit={
                    (event) => {
                        if (email && password) handleLogin({ username: email, password }, event);
                    }
                }>
                    <p className="full-width text-align-center">{t.translate('steps.login.login')}</p>
                    <i className="text-main-color clickable" style={{ margin: '1vh 0' }} onClick={handleToggleCreateAccount}>{t.translate('steps.login.button.register')}</i>
                    
                    <div className="flex-row flex-wrap full-width" style={{ columnGap: '1vw' }}>
                        <div className="flex-column-start" style={{ width: '45%', minWidth: '180px', flexGrow: 1 }}>
                            <Label for="email" label={t.translate('steps.login.email')} required />
                            <Input name="email" value={email} setValue={(e) => setEmail(e.currentTarget.value)} required />
                        </div>
                        <div className="flex-column-start" style={{ width: '45%', minWidth: '180px', flexGrow: 1 }}>
                            <Label for="password" label={t.translate('steps.login.password')} required />
                            <Input name="password" value={password} setValue={(e) => setPassword(e.currentTarget.value)} required type="password" />
                        </div>
                    </div>

                    <span onClick={handleResetPassword} id="forgot-password-text">
                        {t.translate("steps.login.forgot-password")}
                    </span>

                    <Button
                        label={t.translate('steps.login.button.login')}
                        type="submit"
                        disabled={!email || !password}
                        style={{ marginTop: '1vh' }}
                    />
                </form>
                : <div className="flex-column">
                    <p className="full-width text-align-center">{t.translate('steps.login.register')}</p>
                    <i className="text-main-color clickable" style={{ margin: '1vh 0' }} onClick={handleToggleLogin}>{t.translate('steps.login.button.login')}</i>

                    <RegisterForm
                        definedEmail={email}
                        onSuccess={({ email, password: loginPassword }) =>
                            handleLogin({ username: email, password: loginPassword })
                        }
                    />
                </div>
            }
        </div>
    )
}

export default Identify;