import { IIntakeSchedule, defaultIntakeSchedule } from "interfaces/IIntakeSchedule"

export interface IDatabaseDietarySupplement {
    id: number
    name: string
    description?: string
    quantity: number
    intakeSchedule: IIntakeSchedule
}


export const defaultDietarySupplement: IDatabaseDietarySupplement = {
    id: 0,
    name: '',
    description: '',
    quantity: 0,
    intakeSchedule: defaultIntakeSchedule
} 