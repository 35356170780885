import { IDProductOrderOption } from "interfaces/Database";
import apiService from "./api.service";

export interface ICheckoutService {
    createCheckoutSession: (props: {
        solution_nbr: number,
        payment_type_id: number,
        product_order_type_id: number,
        plan_type_id: number,
        platform_id: number,
        options?: IDProductOrderOption[],
        renew: boolean,
        language?: string
    }) => Promise<{ url: string, client_secret: string }>
    createCheckoutSessionForAppointment: (props: {
        quantity: number,
        paymentTypeId: number,
        appointmentType: string,
        platformId: number,
        productOrderTypeId: number,
        renew: boolean,
        language: string
    }) => Promise<{ url: string, client_secret: string }>
}

export class CheckoutService implements ICheckoutService {
    public async createCheckoutSession(data: {
        solution_nbr: number,
        payment_type_id: number,
        product_order_type_id: number,
        plan_type_id: number,
        platform_id: number,
        options?: IDProductOrderOption[],
        renew: boolean
    }) {
        return await apiService.makeApiCall<{ url: string, client_secret: string }>(`/api/checkout/session`, 'post', data);
    }

    public async createCheckoutSessionForAppointment(data: {
        quantity: number,
        paymentTypeId: number,
        appointmentType: string,
        platformId: number,
        productOrderTypeId: number,
        renew: boolean,
        language: string
    }) {
        return await apiService.makeApiCall<{ url: string, client_secret: string }>('/api/checkout/appointment/session', 'post', data);
    }
}

export default function useCheckoutService(): ICheckoutService {
    return new CheckoutService();
}