import banner from 'assets/order_box_banner.png';
import { Loader } from "components";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { IDAppointment, IDAppointmentWithNutritionist } from 'interfaces/Database';
import { IDatabaseAppointment } from "interfaces/Database/IDatabaseAppointment";
import { IDatabaseAppointmentWithNutritionist } from "interfaces/Database/IDatabaseAppointmentWithNutritionist";
import React, { Fragment, useContext, useEffect, useState } from "react";
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from "services";
import appointmentService from "services/appointment.service";
import subscriptionPlanService from "services/subscriptionPlan.service";
import userAuthService from "services/user-auth.service";
import BuyAppointment from "../../Buy/BuyAppointment";
import AllAppointments from "./components/AllAppointments";
import NextAppointment from "./components/NextAppointment";
import NextAppointmentWNutritionist from "./components/NextAppointmentWNutritionist";
import "./scss/PatientAppointment.scss";

const PatientAppointment: React.FC = () => {
    const t = useTranslation('dashboard/patient/appointment');
    
    const { subscriptionTrackingDetail, reloadSubscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ isLoadingMeeting, setIsLoadingMeeting ] = useState<boolean>(false);
    const [ scheduleAppointmentsList, setScheduleAppointmentsList ] = useState<(IDAppointment|IDAppointmentWithNutritionist)[]>([]);
    const [ appointments, setAppointments ] = useState<(IDAppointment|IDAppointmentWithNutritionist)[]>([]);
    const [ nextAppointment, setNextAppointment ] = useState<(IDAppointment|IDAppointmentWithNutritionist)>();
    const [ idAppointmentToSchedule, setIdAppointmentToSchedule ] = useState<number>();
    const [ results, setResults ] = useState<boolean>(false);
    const [ isMeeting, setIsMeeting ] = useState<boolean>(false);

    const [ isLoadingMeetingWNutritionist, setIsLoadingMeetingWNutritionist ] = useState<boolean>(false);
    const [ nextAppointmentWNutritionist, setNextAppointmentWNutritionist ] = useState<IDatabaseAppointmentWithNutritionist>();
    const [ idAppointmentWNutritionistToSchedule, setIdAppointmentWNutritionistToSchedule ] = useState<number>();
    const [ isMeetingWNutritionist, setIsMeetingWNutritionist ] = useState<boolean>(false);

    const fetchData = async () => {
        setIsLoading(true);

        Promise.all([
            reloadSubscriptionTrackingDetail(),
            appointmentService.getNextAppointment()
                .then((res) => {
                    setNextAppointment(res);
                    setIsMeeting(true);
                })
                .catch(() => {
                    setNextAppointment(undefined);
                    setIsMeeting(false);
                }),
            appointmentService.getNextAppointmentWithNutritionist()
                .then((res) => {
                    setNextAppointmentWNutritionist(res);
                    setIdAppointmentWNutritionistToSchedule(res.id);
                    setIsMeetingWNutritionist(true);
                })
                .catch(() => {
                    setNextAppointmentWNutritionist(undefined);
                    setIsMeetingWNutritionist(false);
                }),
            appointmentService.getBookedAndNotBookedAppointments()
                .then(async (res) => {
                    setScheduleAppointmentsList(res);
                    await handleIdAppointmentToSchedule(res);
                })
                .catch(() => {
                    setScheduleAppointmentsList([]);
                    handleIdAppointmentToSchedule([]);
                }),
            appointmentService.getAllAppointmentsExceptNotBooked()
                .then((res) => setAppointments(res))
                .catch(() => setAppointments([])),
            subscriptionPlanService.getSubscriptionProcessing()
                .then((res) => setResults(res?.analyseDone?.isDone ?? false))
        ])
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, [0]);

    const handleVideoMeeting = async () => {
        if (nextAppointment && nextAppointment.appointmentOn) {
            setIsLoadingMeeting(true);
            userAuthService.getOrCreateMeeting(nextAppointment.id)
                .then((res) => {
                    const roomId = res.roomUrl.split('/')[3];

                    window.open(window.location.origin
                        + '/account/meeting/withdoctor/'
                        + (
                            'doctor' in nextAppointment
                                ? nextAppointment.doctor.id
                                : nextAppointment.nutritionist.id
                        )
                        + '/'
                        + roomId
                    );
                })
                .catch((err) => console.warn(err))
                .finally(() => setIsLoadingMeeting(false));
        }
    }

    const handleVideoMeetingWNutritionist = async () => {
        if (nextAppointmentWNutritionist && nextAppointmentWNutritionist.appointmentOn) {
            setIsLoadingMeetingWNutritionist(true);
            userAuthService.getOrCreateMeeting(nextAppointmentWNutritionist.id)
                .then((res) => {
                    const roomId = res.roomUrl.split('/')[3];

                    window.open(window.location.origin+'/account/meeting/withdoctor/'+nextAppointmentWNutritionist.nutritionist.id+'/'+roomId);
                })
                .catch((err) => console.warn(err))
                .finally(() => setIsLoadingMeetingWNutritionist(false));
        }
    }

    const handleIdAppointmentToSchedule = async (appointments: IDatabaseAppointment[]) => {
        const firstAppointment = appointments.find((appointment) => appointment.status.name === 'Not Booked');

        if (firstAppointment) {
            setIdAppointmentToSchedule(firstAppointment.id);
        }
    }

    const renderContent = () => {
        if (isLoading) return <Loader loaderDescription={t.translate('loader-message')} />;

        if (!subscriptionTrackingDetail?.subscriptionPlan) return <DefaultContainer
            wrapperStyle={{ minHeight: '573px' }}
            header={{
                image: banner,
                content: <h2>{t.translate('header.title')}</h2>
            }}
            body={
                <Fragment>
                    <h3>{t.translate('none.title')}</h3>
                    <p>{t.translate('none.desc')}</p>
                    <p>{t.translate('none.none')}</p>
                    <p>{t.translateReplaceValues('none.howTo', [
                        {
                            tag: '{{btnLabel}}',
                            value: t.translate('newMessage')
                        }
                    ])}</p>
                </Fragment>
            }
        />

        return subscriptionTrackingDetail?.handleShouldShowAppointment()
            ? <Fragment>
                <div className="flex-row flex-wrap align-items-stretch full-width flex-gap-col flex-gap-row">
                    {subscriptionTrackingDetail?.doctorAppointment &&
                        <NextAppointment
                            getResults={results}
                            nextAppointment={nextAppointment}
                            startVideoMeeting={handleVideoMeeting}
                            idAppointmentToSchedule={idAppointmentToSchedule}
                            handleRefresh={fetchData}
                            isMeeting={isMeeting}
                            isLoadingMeeting={isLoadingMeeting}
                        />
                    }
                    {subscriptionTrackingDetail?.appointmentWNutritionist &&
                        <NextAppointmentWNutritionist
                            getResults={results}
                            nextAppointment={nextAppointmentWNutritionist}
                            startVideoMeeting={handleVideoMeetingWNutritionist}
                            idAppointmentToSchedule={idAppointmentWNutritionistToSchedule}
                            handleRefresh={fetchData}
                            isMeeting={isMeetingWNutritionist}
                            isLoadingMeeting={isLoadingMeetingWNutritionist}
                        />
                    }
                </div>
                <AllAppointments
                    upcomingAppointments={scheduleAppointmentsList}
                    pastAppointments={appointments}
                />
            </Fragment>
            : <BuyAppointment />
    }

    return (
        <div id="patient-appointment" className="flex-column">
            <DashboardHeader
                preTitle={t.translate('header.my')}
                title={t.translate('header.title')}
            />
            <div className="flex-column full-width">
                {renderContent()}
            </div>
        </div>
    )
}

export default PatientAppointment;
