import React, { createContext, useState } from "react";
import { IUserLanguage } from "interfaces/User/IUserLanguage";
import languageService from "services/language.service";
import languages from "./Languages";

type LanguageContextProps = {
    language: IUserLanguage;
    setLanguage: (language: IUserLanguage) => void;
    refreshLanguage: () => void
}

export const defaultUserLanguage = {
    name: 'Français',
    countryCode: 'fr',
    default: false,
    flagImage: '/static/media/french.31c85019.svg'
}

export const LanguageContext = createContext<LanguageContextProps>({
    language: defaultUserLanguage,
    setLanguage: (language: IUserLanguage) => undefined,
    refreshLanguage: () => undefined
});

export const LanguageProvider: React.FC<({ children: React.ReactNode })> = ({ children }) => {
    const [ language, setLng ] = useState<IUserLanguage>(defaultUserLanguage);

    const refreshLanguage = () => {
        languageService.getCurrentLanguage()
            .then((res) => {
                let lng = languages.filter((lang) => lang.countryCode === res.iso6391)[0];

                if (!lng) lng = languages.filter((lang) => lang.default)[0];

                setLanguage(lng);
            })
            .catch(() => null);
    }

    const setLanguage = (language: IUserLanguage) => {
        setLng(language);
        languageService.saveLSLanguage(language);
    }

    return (
        <LanguageContext.Provider value={{ language, setLanguage, refreshLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}